  <template>
  <div class="d-flex flex-column">
    <drag-and-drop>
      <template v-slot="{setDraggable, acceptDrop}">
        <v-row>
          <v-col>
            <picker
              document-types="group-contributors"
              :disabled="!!(dispute.workflowId || statusWillCloseDispute)"
              group-contributor-types="dispute"
              :label="$t('t.UsePredefinedGroup')"
              :selected-id.sync="groupContributorId"
              class="mb-6 align-self-start"
            />
          </v-col>
        </v-row>
        <v-row class="flex-direction-column">
          <v-col>
            <v-card
              elevation=0
              class="bordered-item ma-0"
            >
              <v-card-text v-on="acceptDrop(dropOwner)">
                <user-picker
                  :selection.sync="owner"
                  :scope="disputeResolverScope"
                  document-types="users"
                  :is-chip-draggable="!!setDraggable && !dispute.workflowId && dispute.canUpdate"
                  :on-chip="setDraggable"
                  :readonly="dispute.workflowId !== null || !dispute.canUpdate || statusWillCloseDispute"
                  :rules="required"
                  :warn-no-consultation="consultationRule"
                  :external-user-cache-type="externalUserCacheType"
                >
                  <template v-slot:prepend>
                    <small>{{$t('t.Owner')}}</small>
                  </template>
                </user-picker>
                <v-row class="mt-n2 mb-n6">
                  <v-checkbox
                    hide-details
                    v-model="ownerCanUpdate"
                    :disabled="dispute.ownerCanUpdateLocked || statusWillCloseDispute"
                    :label="$t('t.CanUpdate')"
                    class="ml-5"
                  />
                  <v-checkbox
                    v-model="ownerCanClose"
                    :disabled=" dispute.ownerCanCloseLocked || statusWillCloseDispute"
                    :label="$t('t.CanClose')"
                    class="ml-5"
                  />
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              elevation=0
              class="bordered-item ma-0"
            >
              <v-card-text>
                <div class="d-flex">
                  <user-picker
                    :selection.sync="creator"
                    :readonly="true"
                    document-types="users"
                  >
                    <template v-slot:prepend>
                      <small>{{$t('t.Creator')}}</small>
                    </template>
                  </user-picker>
                </div>
                <v-row class="mt-n2 mb-n6">
                  <v-checkbox
                    hide-details
                    v-model="creatorCanUpdate"
                    :disabled=" dispute.creatorCanUpdateLocked || statusWillCloseDispute"
                    :label="$t('t.CanUpdate')"
                    class="ml-5"
                  />
                  <v-checkbox
                    v-model="creatorCanClose"
                    :disabled=" dispute.creatorCanCloseLocked || statusWillCloseDispute"
                    :label="$t('t.CanClose')"
                    class="ml-5"
                  />
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="flex-direction-column">
          <v-col>
            <v-card
              elevation=0
              class="bordered-item ma-0"
            >
              <v-card-text v-on="acceptDrop(dropParticipant)">
                <user-picker
                  :selection.sync="participantList"
                  :scope="disputeResolverScope"
                  document-types="users"
                  :is-chip-draggable="!!setDraggable && !dispute.workflowId && dispute.canUpdate"
                  :on-chip="setDraggable"
                  :readonly="dispute.workflowId !== null || !dispute.canUpdate || statusWillCloseDispute"
                  :placeholder="`${$t('t.AddParticipant')}...`"
                  :warn-no-consultation="consultationRule"
                  :external-user-cache-type="externalUserCacheType"
                >
                  <template v-slot:prepend>
                    <small>{{$t('t.Participants')}}</small>
                  </template>

                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content><span>
                          {{$t('t.NoParticipantsAvailables')}}
                        </span></v-list-item-content>
                    </v-list-item>
                  </template>
                </user-picker>
                <v-row class="mt-n2 mb-n6">
                  <v-checkbox
                    v-model="participantsCanUpdate"
                    :disabled=" dispute.participantsCanUpdateLocked || statusWillCloseDispute"
                    :label="$t('t.CanUpdate')"
                    class="ml-5"
                  />
                  <v-checkbox
                    v-model="participantsCanClose"
                    :disabled=" dispute.participantsCanCloseLocked || statusWillCloseDispute"
                    :label="$t('t.CanClose')"
                    class="ml-5"
                  />
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </drag-and-drop>
  </div>
</template>

<script>
import { CacheType } from '@/wasm/pkg'
import Scope from '@/pages/search/controllers/scope'

export default {
  components: {
    DragAndDrop: () => import('@/components/drag-and-drop'),
    Picker: () => import('@/components/picker'),
    UserPicker: () => import('@/components/user-picker')
  },
  data () {
    return {
      externalUserCacheType: CacheType.UserRef,
      required: [
        v => v?.id || this.$t('t.IsRequired')
      ]
    }
  },
  computed: {
    disputeResolverScope () {
      return Scope.disputeResolversAccount(this.dispute.accountId)
    },
    groupContributorId: {
      get () {
        return this.dispute.groupContributorId
      },
      async set (id) {
        let owner
        let participantList
        if (id) {
          const groupContributor = await this.$http().get(`/core/v6/group-contributor/${id}/${this.dispute.accountId}/${this.dispute.createBy}`)
          owner = groupContributor.data.owner
          participantList = groupContributor.data.participants
        } else {
          owner = { id: this.$store.getters.currentUser.id, userRole: null, userRoleName: null, indexOrder: null }
          participantList = []
        }
        this.$emit('update:dispute', Object.assign({}, this.dispute, { groupContributorId: id, owner, participantList }))
      }
    },
    owner: {
      get: function () {
        return this.dispute.owner
      },
      set: function (owner) {
        const participantList = [...this.participantList]
        if (owner) {
          const index = participantList.map(p => p.id).indexOf(owner.id)
          if (index > -1) {
            participantList.splice(index, 1)
          }
        }
        if (owner?.id !== this.dispute.owner?.id) {
          this.$emit('update:dispute', Object.assign({}, this.dispute, { owner, participantList }))
        }
        this.resetDisputeContributor()
      }
    },
    ownerCanUpdate: {
      get: function () {
        return this.dispute.ownerCanUpdate
      },
      set: function (ownerCanUpdate) {
        this.$emit('update:dispute', Object.assign({}, this.dispute, { ownerCanUpdate }))
      }
    },
    ownerCanClose: {
      get: function () {
        return this.dispute.ownerCanClose
      },
      set: function (ownerCanClose) {
        this.$emit('update:dispute', Object.assign({}, this.dispute, { ownerCanClose }))
      }
    },
    creator () {
      return { id: this.dispute.createBy }
    },
    creatorCanUpdate: {
      get: function () {
        return this.dispute.creatorCanUpdate
      },
      set: function (creatorCanUpdate) {
        this.$emit('update:dispute', Object.assign({}, this.dispute, { creatorCanUpdate }))
      }
    },
    creatorCanClose: {
      get: function () {
        return this.dispute.creatorCanClose
      },
      set: function (creatorCanClose) {
        this.$emit('update:dispute', Object.assign({}, this.dispute, { creatorCanClose }))
      }
    },
    participantList: {
      get: function () {
        return this.dispute.participantList
      },
      set: function (participantList) {
        let owner = this.owner
        if (participantList.map(p => p.id).includes(owner?.id)) {
          owner = undefined
        }
        if (!this.lodash.isEqual(participantList.map(p => p.id), this.dispute.participantList.map(p => p.id))) {
          this.$emit('update:dispute', Object.assign({}, this.dispute, { owner, participantList }))
        }
        this.resetDisputeContributor()
      }
    },
    participantsCanUpdate: {
      get: function () {
        return this.dispute.participantsCanUpdate
      },
      set: function (participantsCanUpdate) {
        this.$emit('update:dispute', Object.assign({}, this.dispute, { participantsCanUpdate }))
      }
    },
    participantsCanClose: {
      get: function () {
        return this.dispute.participantsCanClose
      },
      set: function (participantsCanClose) {
        this.$emit('update:dispute', Object.assign({}, this.dispute, { participantsCanClose }))
      }
    }
  },
  methods: {
    update () {
      this.$nextTick(() => { this.groupContributorId = this.dispute.groupContributorId })
    },
    dropOwner (user) {
      if (this.owner && this.owner.id !== user.id) {
        this.participantList = [...this.participantList, this.owner]
      }
      this.$nextTick(() => (this.owner = user))
    },
    dropParticipant (user) {
      if (this.participantList.map(p => p.id).indexOf(user.id) === -1) {
        this.participantList = [...this.participantList, user]
      }
    },
    resetDisputeContributor () {
      this.$nextTick(() => (this.$emit('update:dispute', Object.assign({}, this.dispute, { groupContributorId: null }))))
    },
    consultationRule (user) {
      return Boolean(!this.dispute.isNew && !user.consultationDate && user.id !== this.$store.getters.currentUser.id)
    }
  },
  props: {
    dispute: Object,
    statusWillCloseDispute: Boolean
  }
}
</script>

<style lang="stylus" scoped></style>
